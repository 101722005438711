import Link from "next/link";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

// <TextField type="text" name="title" labels="Blog Title" required={true}/>

const TextField = (props) => {
  const [isValue, setIsValue] = useState("");
  const showLabel = (e) => {
    if (e.target.value) {
      setIsValue("value");
      if (props.setValues) props.setValues(e.target.value);
      if (props.setValuesIndex)
        props.setValuesIndex(
          props.index,
          props.types,
          e.target.value,
          props.types
        );
    } else setIsValue("");

    props.setSelectedNameValue &&
      props.setSelectedNameValue(props.names, e.target.value);
  };
  useEffect(() => {
    if (
      props.defaultValues ||
      props.defaultValues === false ||
      props.actives ||
      props.types === "file"
    )
      setIsValue("value");
  }, [props.defaultValues, props.actives, props.types]);
  return (
    <div className={"field_wrapper " + props.names}>
      {props.types === "file" && props.defaultValues ? (
        <ReactFileLink className="file_link">
          {/* Only shows link to view image */}
          <Link href={props.defaultValues} target="blank" rel="noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
              <path d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z" />
            </svg>
            View "{props.names}" uploaded.
          </Link>
        </ReactFileLink>
      ) : (
        ""
      )}
      <div className="field">
        <Title>{props.title}</Title>
        <Wrapper className={isValue}>
          <input
            type={props.types}
            name={props.names}
            required={props.requireds}
            defaultValue={props.types !== "file" ? props.defaultValues : ""}
            id={props.ids}
            maxLength={props.maxLengths}
            onChange={(e) => showLabel(e)}
            onLoad={(e) => showLabel(e)}
            className={props.widths === "auto" ? "width_auto" : ""}
            readOnly={props.disableds ? props.disableds : false}
            placeholder={props.placeholder}
          />
          <span className="text_label">{props.labels}</span>
        </Wrapper>
        {props.notes && <Notes>{props.notes}</Notes>}
      </div>
    </div>
  );
};

export default TextField;

const Wrapper = styled.div`
  position: relative;
  /* margin-top: 25px; */

  span {
    position: absolute;
    top: 5px;
    left: 15px;
    transition: all 0.3s ease;
    pointer-events: none;
    font-size: 16px;
    color: var(--Gunmetal);
  }
  input {
    border: var(--borderDefault);
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }

    :disabled,
    :readonly {
      background: #f4f4f4;
    }
  }
  input:focus + span,
  &.value span {
    background: #ffffff;
    top: -10px;
    height: 20px;
    padding: 5px;
    margin: 0;
    color: #00838f;
    font-size: 14px;
    pointer-events: initial;
    left: 15px;
    line-height: 10px;
    width: auto;
  }
  input.width_auto {
    width: auto;
  }
`;

const Notes = styled.span`
  font-size: 12px;
`;

const Title = styled.h3`
  margin: 20px 0px;
  font-size: 1em !important;
  margin-bottom: 0px;
`;
const ReactFileLink = styled.h3`
  margin: 20px 0px;
  font-size: 1em !important;
  margin-bottom: 0px;
  svg {
    width: 20px;
    margin-right: 10px;
  }
`;
